import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "displayTimer" }
const _hoisted_2 = { id: "clockdiv" }
const _hoisted_3 = { class: "days" }
const _hoisted_4 = { class: "hours" }
const _hoisted_5 = { class: "minutes" }
const _hoisted_6 = { class: "seconds" }
const _hoisted_7 = {
  key: 0,
  class: "text-center",
  style: {"color":"#fff"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "Mobile_h1_Style" }, "Онлайн служіння:", -1)),
    _cache[5] || (_cache[5] = _createElementVNode("h1", null, "Онлайн служіння:", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_3, _toDisplayString($setup.formatTime($setup.days)), 1),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "smalltext" }, "Дні", -1))
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_4, _toDisplayString($setup.formatTime($setup.hours)), 1),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "smalltext" }, "Години", -1))
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_5, _toDisplayString($setup.formatTime($setup.minutes)), 1),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "smalltext" }, "Хвилини", -1))
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_6, _toDisplayString($setup.formatTime($setup.seconds)), 1),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "smalltext" }, "Секунди", -1))
      ])
    ]),
    ($setup.currentTime <= 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, " Час вийшло! "))
      : _createCommentVNode("", true)
  ]))
}